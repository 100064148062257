import React, { Fragment, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Tab from '@material-ui/core/Tab';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Tabs from '../../components/CustomTabs';
import Spinner from '../../components/CustomSpinner';
import PropertyInfoCard from '../../components/PropertyInfoCard';
import TimeLine from '../../components/TimeLine';
import TimeLineItem from '../../components/TimeLineItem';
import useStyles from './styles';
import { Property } from '../../types/Property';
import {
  Catalog,
} from '../../types/Customer';
import { CustomerVisit } from '../../types/Visit';
import AvailableAgents from '../../components/AvailableAgents';
import { AgentsProperties, UserType as User, AvailableAgentsType } from '../../types/User';
import { GET_PROPERTY, ADD_AGENT, REMOVE_AGENT } from '../../graphql/queries/PropertyDetails';
import { GET_COORDINATES } from '../../graphql/queries/Properties';
import {
  convertDateToTimeWithTimezone,
  convertDateWithTimezone,
  transformGraphQlErrorMessage,
} from '../../utils/helpers';
import IntegrationTab from './IntegrationTab/IntegrationTab';
import { IIntegration } from './IntegrationTab/Integration/Integration';
import AutomationTab from './AutomationTab';

type Props = {
  propertyDetails: Property;
  loading?: boolean;
} &
RouteComponentProps<{ propertyId: string }>;

const PropertyDetails: React.FC<Props> = props => {
  const {
    match,
  } = props;
  const propertyId = parseInt(match.params.propertyId, 10);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [property, setProperty] = useState<Property & Catalog>();
  const [assignedAgents, setAssignedAgents] = useState<Array<AgentsProperties>>([]);
  const [availableAgents, setAvailableAgents] = useState<Array<AvailableAgentsType>>([]);
  const [visits, setVisits] = useState<Array<CustomerVisit>>([]);
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [newAgent, setNewAgent] = useState<User>({
    firstname: '',
    lastname: '',
    username: '',
    is_project_manager: false,
    isPM: false,
  });
  const { loading, data } = useQuery<{
    property: Property & Catalog & { integrations: IIntegration[] },
    visits: CustomerVisit[],
    propertyAgents: AgentsProperties[],
  }>(
    GET_PROPERTY,
    {
      variables: { property_id: propertyId },
      fetchPolicy: 'network-only',
    },
  );

  const [addAgent] = useMutation<{ addAgent: AgentsProperties }>(
    ADD_AGENT,
  );

  const [removeAgent] = useMutation<{ removeAgent: AgentsProperties }>(
    REMOVE_AGENT,
  );

  function handleTabChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setTabIndex(newTabIndex);
  }

  function handleChange(field:{[key: string]: string}) {
    const {
      LASSO_project_id: lassoProjectId,
      novihome_integration: novihomeIntegration,
      nternow_hub_backup_code: nternowHubBackupCode,
      property_backup_code: propertyBackupCode,
      trade_code: propertyTradeCode,
      sales_code: propertySalesCode,
    } = field;

    if ('LASSO_project_id' in field) {
      setProperty((prevState: any) => ({ ...prevState, LASSO_project_id: lassoProjectId }));
    }

    if ('novihome_integration' in field) {
      setProperty((prevState: any) => ({
        ...prevState, novihome_integration: novihomeIntegration,
      }));
    }

    if ('nternow_hub_backup_code' in field) {
      setProperty((prevState: any) => ({
        ...prevState, nternow_hub_backup_code: nternowHubBackupCode,
      }));
    }

    if ('property_backup_code' in field) {
      setProperty((prevState: any) => ({ ...prevState, property_backup_code: propertyBackupCode }));
    }

    if ('trade_code' in field) {
      setProperty((prevState: any) => ({ ...prevState, trade_code: propertyTradeCode }));
    }

    if ('sales_code' in field) {
      setProperty((prevState: any) => ({ ...prevState, sales_code: propertySalesCode }));
    }
  }

  function handleInputChange(value: User) {
    setNewAgent((prevState: User) => ({
      ...prevState,
      ...value,
    }));
  }

  useEffect(() => {
    if (data) {
      setProperty(data.property);
      setVisits(data.property?.visits ?? []);
      setAssignedAgents(data.property?.assignedAgents ?? []);
      setIntegrations(data.property.integrations ?? []);
      const agents = data.property?.availableAgents
        .filter(el => !data.property?.assignedAgents
          .some(agent => el.username === agent.agent_username));
      setAvailableAgents(agents
        ? agents.map(obj => ({
          value: obj,
          label: `${obj.isPM ? 'PM' : 'AG'} ${obj.firstname} ${obj.lastname}`,
        })) : []);
    }
  }, [loading, data]);

  function onAddAgent() {
    addAgent({
      variables: {
        agent: {
          property_id: propertyId,
          is_project_manager: !!newAgent.isPM, // need to pass through graphql validation
          agent_username: newAgent.username,
        },
      },
    }).then(() => {
      const assigned = {
        property_id: propertyId,
        is_project_manager: newAgent.isPM ?? false,
        agent_username: newAgent.username,
        user: newAgent,
      };
      setAssignedAgents(prevAssigned => prevAssigned.concat(assigned));
      setAvailableAgents(prevState => prevState
        .filter(el => el.value.username !== newAgent.username));
      setNewAgent({
        firstname: '',
        lastname: '',
        username: '',
        is_project_manager: false,
        isPM: false,
      });
      enqueueSnackbar('Agent added', { variant: 'success' });
    })
      .catch(res => {
        enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred', { variant: 'error' });
      });
  }

  function removeAgentFromAssignedList(agent: AgentsProperties) {
    setAssignedAgents(assignedAgents
      .filter(propertyAgent => propertyAgent.agent_username !== agent.agent_username));
    setAvailableAgents(prevState => prevState.concat({
      value: {
        firstname: agent.user.firstname,
        lastname: agent.user.lastname,
        username: agent.agent_username,
        email: agent.user.email,
        phone: agent.user.phone,
        is_project_manager: agent.is_project_manager ?? false,
        isPM: agent.is_project_manager ?? false,
      },
      label: `${agent.is_project_manager ? 'PM' : 'AG'} ${agent.user.firstname} ${agent.user.lastname}`,
    }));
  }


  function onRemoveAgent(agent: AgentsProperties) {
    removeAgent({
      variables: {
        username: agent.agent_username,
        property_id: propertyId,
      },
    }).then(() => {
      removeAgentFromAssignedList(agent);
      enqueueSnackbar('Removed', { variant: 'success' });
    })
      .catch(res => {
        enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred', { variant: 'error' });
      });
  }

  const [getCoordinates] = useMutation<{ coordinates: { lng: number, lat: number } }>(
    GET_COORDINATES,
    { errorPolicy: 'all' },
  );

  const fetchCoordinates = () => {
    getCoordinates({
      variables: {
        house_num: Number(property?.house_num ?? 0),
        address: property?.address_1 ?? '',
        city: property?.city ?? '',
        state: property?.state ?? '',
      },
    }).then(({ data: coordsData }) => {
      setProperty((prevState: any) => ({
        ...prevState,
        longitude: coordsData?.coordinates?.lng ?? 0,
        latitude: coordsData?.coordinates?.lat ?? 0,
      }));
      enqueueSnackbar(transformGraphQlErrorMessage('Success'), { variant: 'success' });
    }).catch(res => {
      enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred', { variant: 'error' });
    });
  };

  return (
    <Fragment>
      {loading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <div className={classes.actionsBar}>
        <Paper elevation={3}>
          <Button>
            <ArrowLeftIcon />
          </Button>
          <Button>
            <ArrowRightIcon />
          </Button>
        </Paper>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/properties"
          >
            <ArrowLeftIcon />
            Properties
          </Button>
          <Button variant="contained" className={classes.actionButton} onClick={fetchCoordinates}>
            Get Coordinates
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid container spacing={4}>
          {property && (
            <Fragment>
              <Grid item xs={12} md={4}>
                <PropertyInfoCard
                  info={property}
                  assignedPM={assignedAgents.find(agent => agent.is_project_manager)}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className={classes.rightSide}>
                  <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Timeline" />
                    <Tab label="Agents" />
                    <Tab label="Automations" />
                    {integrations.length !== 0 && (
                    <Tab label="Integrations" />
                    )}
                  </Tabs>
                  <div className={classes.tabsContent}>
                    {tabIndex === 0 && (
                      <TimeLine>
                        {visits.map(visit => (
                          <TimeLineItem key={visit.customer_visit_id}>
                            <div>
                              <Typography className={classes.timestamp}>
                                {visit.modified_on ? convertDateWithTimezone(
                                  new Date(visit.modified_on),
                                  visit.property?.timezoneCatalog?.catalog_id,
                                )
                                  : ''}
                                {'  '}
                                {visit.modified_on ? convertDateToTimeWithTimezone(
                                  new Date(visit.modified_on),
                                  visit.property?.timezoneCatalog?.catalog_id,
                                )
                                  : ''}
                                {`  ${visit.property?.timezoneCatalog?.value}`}
                              </Typography>
                              <Typography>
                                {`${visit.customer?.firstname ?? ''} ${visit.customer?.lastname ?? ''}, ${visit.customer?.cell_phone_1 ?? ''
                                }:`}
                              </Typography>
                              <Typography>{visit.remarks}</Typography>
                            </div>
                          </TimeLineItem>
                        ))}
                      </TimeLine>
                    )}
                    {tabIndex === 1 && (
                      <AvailableAgents
                        availableAgents={availableAgents}
                        assignedAgents={assignedAgents}
                        handleInputChange={handleInputChange}
                        onAddAgent={onAddAgent}
                        newAgent={newAgent}
                        onRemoveAgent={onRemoveAgent}
                      />
                    )}
                    {tabIndex === 2 && (
                      <AutomationTab
                        propertyId={propertyId}
                        isRental={data?.property.is_rental ?? false}
                        tempBackupCode={property.temp_backup_code}
                        nternowHubBackupCode={property.nternow_hub_backup_code}
                        propertyBackupCode={property.property_backup_code}
                        propertyTradeCode={property.trade_code}
                        propertySalesCode={property.sales_code}
                        propertyResidentCode={property.resident_code}
                        handleAutomationsChange={handleChange}
                      />
                    )}
                    {tabIndex === 3 && (
                    <IntegrationTab
                      integrations={integrations}
                      propertyId={propertyId}
                      handleIntegrationsChange={handleChange}
                    />
                    )}
                  </div>
                </div>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(PropertyDetails);
