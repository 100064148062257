// import { add } from 'date-fns';

import { format, utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import { BaseProperty as Property } from '../types/Property';

export type ValidationErrors<T> = {
  [key in keyof T]?: string;
};

interface IntegrationAttribute {
  key: string;
  isBoolean: boolean;
}

type DefaultValidationErrors = { [key: string]: string };

export type TimezoneCode = 51 | 52 | 53 | 54 | 0;

export function beautifyErrors<T = DefaultValidationErrors>(yupErrors: any): ValidationErrors<T> {
  return Object
    .fromEntries(
      yupErrors.inner.map((e: { path: string, message: string }) => [e.path, e.message]),
    );
}

export const emptyStringToNull = (value: string, originalValue: string) => {
  if (originalValue === '') {
    return null;
  }
  return value;
};

const getDateTimeInTimezoneWithPattern = (date: Date, pattern: string, timezone: TimezoneCode) => ({
  51: format(utcToZonedTime(date, 'America/Denver'), pattern, { timeZone: 'America/Denver' }),
  52: format(utcToZonedTime(date, 'America/New_York'), pattern, { timeZone: 'America/New_York' }),
  53: format(utcToZonedTime(date, 'America/Chicago'), pattern, { timeZone: 'America/Chicago' }),
  54: format(utcToZonedTime(date, 'America/Los_Angeles'), pattern, { timeZone: 'America/Los_Angeles' }),
  0: format(utcToZonedTime(date, 'America/New_York'), pattern, { timeZone: 'America/New_York' }),
}[timezone]);


export const parseDateWithoutTimezone = (dateString: string | null) => {
  if (!dateString || dateString === '') return '';
  return moment.parseZone(dateString).format('MM/DD/YYYY');
};

export const convertDateWithTimezone = (date: Date, timezone: TimezoneCode = 0) => {
  const pattern = 'MM/dd/yyyy';
  return getDateTimeInTimezoneWithPattern(date, pattern, timezone);
};

export const convertDateToTimeWithTimezone = (date: Date, timezone: TimezoneCode = 0) => {
  const pattern = 'hh:mm a';
  return getDateTimeInTimezoneWithPattern(date, pattern, timezone);
};

export const convertDateToFullTimeWithTimezone = (date: Date, timezone: TimezoneCode = 0) => {
  const pattern = 'hh:mm:ss a';
  return getDateTimeInTimezoneWithPattern(date, pattern, timezone);
};

const findTimeZone = (tz: number) => {
  const zones: { [key: number]: string } = {
    51: 'America/Denver',
    52: 'America/New_York',
    53: 'America/Chicago',
    54: 'America/Los_Angeles',
  };

  return zones[tz] || 'America/New_York';
};

export const convertTimeToPropertyTimezone = (date: Date, tz: TimezoneCode = 0): number => {
  const timeZone = findTimeZone(tz);
  const timeOptions = {
    timeZone, hour: 'numeric', minute: 'numeric', hour12: false,
  };
  const time: string = date.toLocaleTimeString('en-US', timeOptions);

  return Number(time.split(':').shift());
};

export const generateLabel = (text: string) => text
  .split(/(?=[A-Z][^A-Z_])|_/g)
  .map(word => word.charAt(0).toUpperCase().concat(word.slice(1)))
  .join(' ');

export const transformGraphQlErrorMessage = (message: string) => message.replace('GraphQL error:', '');

export const transformValidationErrorMessage = (messages: Object) => {
  const values = Object.values(messages);

  if (values.length > 0) {
    return `Validation error: ${values[0]}`;
  }
}

export const changeVisitStatus = (value: string | undefined): string => {
  if (!value) {
    return '';
  }

  const deprecatedValues = ['1st', '2nd', '3rd', '4th', '5th'];

  return deprecatedValues.includes(value) ? 'Accessed' : value;
};

export const onlyNumberEnter = (value: string) => {
  if (isNaN(Number(value))) {
    return parseInt(value);
  }

  return Number(value);
};

interface Attribute {
  [key: string]: IntegrationAttribute;
}

export const transformLockCode = (code?: number | string) => {
  const longCode = code?.toString().padStart(4, '0') ?? '';

  const separatedLockCodeByDashes = longCode.length > 6
    ? longCode.replace(/^(?=[\d]{7,9})([\d]{3})([\d]{3})([?\d]+)$/, '$1-$2-$3')
    : longCode.replace(/^(?=[\d]{6})([\d]{3})([\d]{3})$/, '$1-$2');

  return separatedLockCodeByDashes;
};

export const getIntegrationAttributes = (name: string): IntegrationAttribute => {
  const attributes: Attribute = {
    Novihome: {
      key: 'Novihome Apikey',
      isBoolean: false,
    },
    'Lasso CRM': {
      key: 'Lasso Id',
      isBoolean: false,
    },
    'Smart Touch CRM': {
      key: 'STI Builder Id',
      isBoolean: false,
    },
    'Follow Up Boss': {
      key: 'Follow Up Boss',
      isBoolean: true,
    },
    Boomtown: {
      key: 'Boomtown',
      isBoolean: true,
    },
    TRI_Pointe: {
      key: 'TRI_Pointe',
      isBoolean: true,
    },
    Academy: {
      key: 'Academy',
      isBoolean: true,
    },
    'Salesforce API': {
      key: 'Salesforce Login Url',
      isBoolean: false,
    },
  };
  return attributes[name];
};

export const checkOutsideTourHoursFrames = ({
  tourHoursStart,
  tourHoursEnd,
  timezone,
}: Property) => {
  if (!tourHoursStart || !tourHoursEnd) {
    return false;
  }

  const tz: TimezoneCode = typeof timezone === 'object' ? timezone?.catalog_id : timezone;
  const convertedDate = convertTimeToPropertyTimezone(new Date(), tz);

  return convertedDate < tourHoursStart || convertedDate > tourHoursEnd;
};

export const getQueryObject = (search: string) => new URLSearchParams(search);

export const getSmartLockTypeValue = (id: number, types: any) : string => {
  const foundType = types?.data?.allSmartLockTypes.find((type: any) => type.id === id)

  if (foundType) {
    return foundType.smart_lock_type ?? '';
  }
  
  return '';
}
