/* eslint-disable @typescript-eslint/camelcase */
import React, {
  ChangeEvent,
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  Checkbox, Collapse, FormControlLabel, MenuItem, Paper,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { loader } from 'graphql.macro';
import Tab from '@material-ui/core/Tab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import InputProperty from '../../types/inputTypes/Property';
import Builder from '../../types/Builder';
import useStyles from './styles';
import CustomButton from '../CustomButton';
import CommunitySearch from '../CommunitySearch';
import Tabs from '../CustomTabs';
import AvailableAgents from '../AvailableAgents';
import CustomTextField from '../CustomTextField';
import Spinner from '../CustomSpinner';
import { propertyInputCreateSchema } from './PropertyInputValidation';
import { beautifyErrors, transformGraphQlErrorMessage, ValidationErrors } from '../../utils/helpers';
import Lock from '../../types/Lock';
import LockSearch from '../LockSearch';
//import SmartLock from '../../types/SmartLock';
//import SmartLockSearch from '../SmartLockSearch';
import { AgentsProperties, UserType } from '../../types/User';
import { SetNewAgentHook } from '../../hooks/propertyInput';
import {
  CREATE_PROPERTY, GET_AGENTS, ADD_AGENTS, GET_COORDINATES,
} from '../../graphql/queries/Properties';
import StatusSelect from '../StatusSelect/StatusSelect';
import StatesSelect from '../StatesSelect/StatesSelect';
import TimezoneSelect from '../TimezoneSelect/TimezoneSelect';
import CustomDatePicker from '../CustomDatePicker';
import Community from '../../types/Community';

type Props = {
  loading?: boolean;
} & RouteComponentProps & { match: { params: { visit_id: string } } };

const query = loader('./staticData.graphql');

const PropertyInput: React.FC<Props> = ({ history }) => {
  const classes = useStyles();
  const { newAgent, setNewAgent } = SetNewAgentHook();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [chosenLock, chooseLock] = useState<Lock>();
  const [savedProperty, setSavedProperty] = useState<number | null>(0);
  const [assignedAgents, setAssignedAgents] = useState<Array<AgentsProperties>>([]);
  const [availableAgents, setAvailableAgents] = useState<Array<UserType>>([]);
  const [property, setProperty] = useState<InputProperty>(new InputProperty());
  const { enqueueSnackbar } = useSnackbar();
  const [updateProperty] = useMutation<{ updateProperty: InputProperty }>(
    CREATE_PROPERTY,
    { errorPolicy: 'all' },
  );
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<InputProperty>>({});
  const { data, loading } = useQuery<{ builders: Builder[] }>(query);
  const builders = data?.builders ?? [];
  const handlePropertyChange = useCallback(
    ({ target: { name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const isLassoProjectIdValid = !Number.isNaN(
        Number(value),
      );

      if (name === 'LASSO_project_id' && !isLassoProjectIdValid && value.length > 0) {
        return;
      }

      if (name) {
        setProperty((prevLock: InputProperty) => ({
          ...prevLock,
          [name]: value,
        }));
      }
      if (name === 'isModel') {
        setProperty((prevLock: InputProperty) => ({
          ...prevLock,
          [name]: checked,
        }));
      }

      if (name === 'alarm') {
        setProperty(prevState => ({
          ...prevState,
          [name]: checked,
          alarm_company: checked ? property.alarm_company : '',
          armed_hours: checked ? property.armed_hours : '',
          disarm_code: checked ? property.disarm_code : '',
          emergency_notes: checked ? property.emergency_notes : '',
        }));
      }
    },
    [property],
  );

  function handleChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setTabIndex(newTabIndex);
  }

  const { data: agentData, refetch } = useQuery<{ getUsersByBuilderId: UserType[] }>(
    GET_AGENTS,
    {
      variables: {
        id: property.builder_id,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [getCoordinates] = useMutation<{ coordinates: { lng: number, lat: number } }>(
    GET_COORDINATES,
    { errorPolicy: 'all' },
  );

  useEffect(() => {
    if (property.builder_id) {
      refetch({
        id: property.builder_id,
      });
    }
  }, [property.builder_id, refetch]);

  useEffect(() => {
    if (agentData) {
      const assigned = agentData?.getUsersByBuilderId
      .filter(agent => agent.isPM)
      .map(agent => ({
        property_id: 0,
        is_project_manager: agent.isPM ?? false,
        agent_username: agent.username,
        user: agent,
      }));

      setAssignedAgents(assigned);
    }
  }, [agentData]);

  const handleLockChange = (lock: Lock) => {
    if (lock.serial_number !== '') {
      chooseLock(lock);
    } else chooseLock(undefined);
    setProperty((prevProperty: InputProperty) => ({
      ...prevProperty,
      lock_serial_number: lock?.serial_number ?? '',
    }));
  };

  // const handleSmartLockChange = (smartLock: SmartLock) => {
  //   setProperty((prevProperty: InputProperty) => ({
  //     ...prevProperty,
  //     smart_lock_serial_number: smartLock?.serial_number ?? '',
  //   }));
  // };

  const handleCommunityChange = (community: Community) => {
    setProperty((prevProperty: InputProperty) => ({
      ...prevProperty,
      address_2: community.id || 0,
      subdivision: community.name ?? '',
      city: community.city || '',
      state: community.state || '',
      LASSO_project_id: community.lasso_community_project_id || '',
      novihome_integration: community.novihome_integration || '',
      nternow_hub_backup_code: community.nternow_hub_backup_code || '',
      property_backup_code: community.community_backup_code || '',
      trade_code: community.trade_code || '',
      sales_code: community.sales_code || '',
    }));
  };

  // const handleDispositionChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   setProperty(prevState => ({ ...prevState, propertyStatusId : ));
  // };

  const [savePropertyAgents] = useMutation(
    ADD_AGENTS,
    { errorPolicy: 'all' },
  );

  const fetchCoordinates = () => {
    getCoordinates({
      variables: {
        house_num: Number(property.house_num),
        address: property.address_1,
        city: property.city,
        state: property.state,
      },
    }).then(({ data: coordsData }) => {
      setProperty({
        ...property,
        longitude: coordsData?.coordinates?.lng ?? 0,
        latitude: coordsData?.coordinates?.lat ?? 0,
      });
    }).catch(res => {
      enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred', { variant: 'error' });
    });
  };

  const handlePropertySave = async () => {
    try {
      const { address_2, agents, ...rest } = property;
      const uProperty = propertyInputCreateSchema.validateSync(rest, { abortEarly: false });
      setValidationErrors({});
      updateProperty({
        variables: {
          property: {
            ...rest,
            ...uProperty,
          },
          communityId: address_2,
        },
      }).then(res => {
        const property_id = res && res.data ? res.data.updateProperty.property_id : 0;
        setSavedProperty(property_id);
        savePropertyAgents({
          variables: {
            propertyAgents: assignedAgents.map(({
              agent_username,
              is_project_manager,
            }) => ({ property_id, agent_username, is_project_manager })),
          },
        });
        history.push(`/properties/${property_id}`);
      })
        .catch(res => {
          enqueueSnackbar(transformGraphQlErrorMessage(res?.message) ?? 'An error occurred',
            { variant: 'error' });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<InputProperty>(errors));
    }
  };

  const parseAgents = (agents: any) => agents.reduce((acc: Array<any>, agent: UserType) => {
    const agentName = agent.username.split('.')
      .map(name => ` ${name[0].toUpperCase()}${name.slice(1)}`).join(' ');
    const agentFullName = `${agent.isPM ? 'PM' : 'AG'} ${agentName}`;
    return [...acc, { value: agent, label: agentFullName }];
  }, []);

  const handleInputChange = (value: UserType) => {
    setNewAgent((prevState: UserType) => ({
      ...prevState,
      ...value,
    }));
  };
  const handleDateChange = (date: string, name: string) => {
    if (Date.parse(date)) {
      const newDate = new Date(date);
      newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
      const UTCDateString = newDate.toISOString();
      setProperty(prevState => ({ ...prevState, [name]: UTCDateString }));
    }
  };

  const onAddAgent = () => {
    const assigned = {
      property_id: 0,
      is_project_manager: newAgent.isPM ?? false,
      isPM: newAgent.isPM,
      agent_username: newAgent.username,
      user: newAgent,
    };

    setAssignedAgents(prevAssigned => [...prevAssigned, assigned]);
    setAvailableAgents(prevState => prevState.filter(el => el.username !== newAgent.username));
    setNewAgent({
      firstname: '',
      lastname: '',
      username: '',
      is_project_manager: newAgent.isPM ?? false,
      isPM: newAgent.isPM,
    });
    return enqueueSnackbar('Agent added', { variant: 'success' });
  };

  const removeAgentFromAssignedList = (agent: AgentsProperties) => {
    setAssignedAgents(assignedAgents
      .filter(propertyAgent => propertyAgent.agent_username !== agent.agent_username));

    setAvailableAgents(prevState => [...prevState, agent.user]);
  };

  const onRemoveAgent = (agent: AgentsProperties) => {
    removeAgentFromAssignedList(agent);
    enqueueSnackbar('Successfully removed', { variant: 'success' });
  };

  return (
    <Fragment>
      {loading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <div className={classes.actionsBar}>
        <Paper elevation={3}>
          <Button>
            <ArrowLeftIcon />
          </Button>
          <Button>
            <ArrowRightIcon />
          </Button>
        </Paper>
        {Boolean(savedProperty)
          && (
            <Paper className={classes.propertyId}>
              Property Id:
              {' '}
              {savedProperty}
            </Paper>
          )}
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/properties"
          >
            <ArrowLeftIcon />
            Properties
          </Button>
          <Button variant="contained" className={classes.actionButton} onClick={fetchCoordinates}>
            Get Coordinates
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Tabs value={tabIndex} onChange={handleChange}>
                <Tab label="Property Info" />
                <Tab label="Agents" />
              </Tabs>
              {tabIndex === 1 ? (
                <AvailableAgents
                  availableAgents={parseAgents(availableAgents)}
                  assignedAgents={assignedAgents}
                  handleInputChange={handleInputChange}
                  onAddAgent={onAddAgent}
                  newAgent={newAgent}
                  onRemoveAgent={onRemoveAgent}
                />
              ) : (
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      id="builder_id"
                      required
                      label="Client"
                      name="builder_id"
                      value={property.builder_id}
                      select
                      onChange={handlePropertyChange}
                      error={Boolean(property.builder_id)}
                      helperText={validationErrors.builder_id ? 'Client field is required' : ''}
                    >
                      <MenuItem value={0} disabled>
                        Select client from a list
                      </MenuItem>
                      {builders.map(builder => (
                        <MenuItem key={builder.builder_id} value={builder.builder_id}>
                          {builder.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="builder"
                      name="builder"
                      label="Builder"
                      value={property.builder}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} direction="column" container justify="flex-end">
                    <CommunitySearch handleChange={handleCommunityChange} builderId={property?.builder_id} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TimezoneSelect
                      fullWidth
                      margin="normal"
                      id="timezone"
                      name="timezone"
                      label="Timezone"
                      required
                      defaultTimezone={property.timezone}
                      onChange={handlePropertyChange}
                      error={Boolean(property.timezone)}
                      helperText={validationErrors.timezone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="house_num"
                      name="house_num"
                      label="Lot Number"
                      value={property.house_num}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="address_1"
                      name="address_1"
                      label="Full Street Address"
                      value={property.address_1}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="subdivision"
                      name="subdivision"
                      label="Subdivision"
                      value={property.subdivision}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="marketing_name"
                      name="marketing_name"
                      label="Marketing Name"
                      value={property.marketing_name}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="city"
                      name="city"
                      label="City"
                      value={property.city}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StatesSelect
                      margin="normal"
                      fullWidth
                      id="state"
                      label="State/Province"
                      name="state"
                      value={property.state}
                      select
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="zipcode"
                      name="zipcode"
                      label="Zip code"
                      value={property.zipcode}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="url_1"
                      name="url_1"
                      label="Url"
                      placeholder="http://"
                      value={property.url_1}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="rental_application_url"
                      name="rental_application_url"
                      label="Rental Application Url"
                      placeholder="http://"
                      value={property.rental_application_url}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="latitude"
                      name="latitude"
                      label="Latitude"
                      value={property.latitude}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="longitude"
                      name="longitude"
                      label="Longitude"
                      value={property.longitude}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="price"
                      name="price"
                      label="Price"
                      value={property.price}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="bedrooms"
                      name="bedrooms"
                      label="Bedrooms"
                      value={property.bedrooms}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="halfBathrooms"
                      name="halfBathrooms"
                      label="Half Bathrooms"
                      value={property.halfBathrooms}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="fullBathrooms"
                      name="fullBathrooms"
                      label="Full Bathrooms"
                      value={property.fullBathrooms}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="squareFootage"
                      name="squareFootage"
                      label="Square Footage"
                      value={property.squareFootage}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="notes"
                      name="grouping"
                      label="Notes"
                      value={property.grouping}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="remarks"
                      name="remarks"
                      label="Installer"
                      value={property.remarks}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: '2px' }}>
                    <LockSearch
                      addLabel
                      styles={{ zIndex: 2 }}
                      onLockChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StatusSelect
                      margin="normal"
                      fullWidth
                      id="propertyStatusId"
                      name="propertyStatusId"
                      statusType="property"
                      label="Disposition"
                      value={property.propertyStatusId}
                      select
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={4}>
                      <CustomDatePicker
                        fullWidth
                        label="Install date"
                        inputVariant="standard"
                        value={property.lock_assigned || new Date()}
                        placeholder="10/10/2012"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'lock_assigned')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="LASSO_project_id"
                      name="LASSO_project_id"
                      label="Lasso Project Id"
                      value={property?.LASSO_project_id ?? ''}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="novihome_integration"
                      name="novihome_integration"
                      label="Novihome Integration"
                      value={property?.novihome_integration ?? ''}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="nternow_hub_backup_code"
                      name="nternow_hub_backup_code"
                      label="NterNow Hub Backup Code"
                      value={property?.nternow_hub_backup_code ?? ''}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="property_backup_code"
                      name="property_backup_code"
                      label="Property Master Code"
                      value={property?.property_backup_code ?? ''}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="trade_code"
                      name="trade_code"
                      label="Trade Code"
                      value={property?.trade_code ?? ''}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      multiline
                      margin="normal"
                      id="sales_code"
                      name="sales_code"
                      label="Sales Code"
                      value={property?.sales_code ?? ''}
                      onChange={handlePropertyChange}
                    />
                  </Grid>
                  {chosenLock && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <StatusSelect
                          label="Lock status"
                          statusType="lock"
                          disabled
                          name="status"
                          value={chosenLock?.lockStatusId ?? '1'}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs>
                    <Collapse in={Boolean(property.alarm)} className={classes.alarmContainer}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            multiline
                            margin="normal"
                            id="alarm_company"
                            name="alarm_company"
                            label="Alarm Company"
                            value={property.alarm_company}
                            onChange={handlePropertyChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            multiline
                            margin="normal"
                            id="armed_hours"
                            name="armed_hours"
                            label="Armed Hours"
                            value={property.armed_hours}
                            onChange={handlePropertyChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            multiline
                            margin="normal"
                            id="disarm_code"
                            name="disarm_code"
                            label="Disarm Code"
                            value={property.disarm_code}
                            onChange={handlePropertyChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            margin="normal"
                            rows={4}
                            id="emergency_notes"
                            name="emergency_notes"
                            label="Emergency Notes"
                            value={property?.emergency_notes ?? ''}
                            onChange={handlePropertyChange}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={4}>
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="secondary"
                        name="isModel"
                        checked={property.isModel}
                        onChange={handlePropertyChange}
                      />
                      )}
                    label="Is Model"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="secondary"
                        name="alarm"
                        checked={property.alarm}
                        onChange={handlePropertyChange}
                      />
                      )}
                    label="Alarm"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} justify="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid item>
                  {tabIndex === 0 && (
                    <CustomButton variant="orange" onClick={handlePropertySave}>
                      Add
                    </CustomButton>
                  )
                  }
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(PropertyInput);
